<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<vnc-dialog [classes]="['vnc-common-dialog']" [headerTemplate]="headerTemplate"
    classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
</vnc-dialog>
<ng-template #headerTemplate>
    {{ headerTranslationKey | translate }}
    <span></span>
    <div class="close-btn">
        <vnc-icon-container (click)="close()">
            <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </vnc-icon-container>
    </div>
</ng-template>
<ng-template #bodyTemplate>
    {{ bodyTranslationKey | translate }}
</ng-template>

<ng-template #footerTemplate>
    <div class="footer-actions">
        <button (click)="close()" class="vnc-default vnc-button">{{ cancelTranslationKey | translate }}</button>
        <button *ngIf="!hideAction" (click)="action()" class="vnc-primary vnc-button">{{ actionTranslationKey | translate }}</button>
    </div>
</ng-template>
